@use "src/styles/vars.scss" as *;

.app-timeline {
	display: grid;
	grid-template-columns: 150px 1fr 1.5fr;
	gap: 20px;
	margin-bottom: 60px;
	position: relative;

	&:last-child {
		margin-bottom: 0;
	}

	&:not(:last-child)::after {
		content: "";
		position: absolute;
		left: 130px;
		top: 20px;
		height: calc(100% + 60px);
		width: 2px;
		background-image: linear-gradient(to bottom, #ffffff4d 50%, transparent 50%);
		background-size: 2px 10px;
		background-repeat: repeat-y;
		z-index: 0;
	}

	&::before {
		content: "";
		position: absolute;
		left: 123px;
		top: 5px;
		width: 16px;
		height: 16px;
		background-color: var(--c-white);
		border-radius: 50%;
		z-index: 1;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		width: min-content;
		height: fit-content;
		position: sticky;
		top: 130px;

		@include max-width($scr-sm) {
			position: initial;
		}

	}

	&__content {
		position: relative;

		// Horizontal line
		&::before {
			content: "";
			position: absolute;
			top: 12px;
			left: -31px;
			width: 100%;
			height: 2px;
			background-color: var(--c-white);
		}
	}

	&__year {
		font-size: calc(var(--text-size-xxl) + 8px);
		font-weight: var(--text-weight-bold);
		color: var(--c-white);
		text-align: center;

		&--text {
			font-size: calc(var(--text-size-l) + 2px);
			font-weight: var(--text-weight-thin);
			margin: 0;
		}
	}

	&__title {
		font-size: calc(var(--text-size-xxl) - 2px);
		font-weight: var(--text-weight-bold);
		color: var(--c-white);
		margin-top: 30px;
		margin-bottom: 10px;
		word-wrap: break-word;
		padding: 10px 15px 0 0;
	}

	&__description {
		font-size: var(--text-size-m);
		font-weight: var(--text-weight-regular);
		color: var(--c-white);
		margin: 0;
	}

	&:last-of-type .app-timeline__content::before {
		top: 15px;
		background-color: var(--theme-highlight);
	}

	&:last-of-type::before {
		border: 3px solid var(--theme-highlight);
		box-sizing: content-box;
		left: 120px;

		@include max-width($scr-sm) {
			left: 5px;
		}
	}

	@include max-width($scr-sm) {
		display: flex;
		flex-direction: column;
		padding: 0 0 0 40px;
		gap: 0;

		&::before {
			left: 8px;
			top: 4px;
		}

		&:not(:last-child)::after {
			left: 15px;
			top: 20px;
		}

		&__wrapper {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			width: 100%;
			cursor: pointer;
		}

		&__year {
			margin-bottom: 8px;
			font-size: var(--text-size-xxl);
			font-weight: var(--text-weight-normal);

			&--text {
				font-size: var(--text-size-m);
			}
		}

		&__content {
			&::before {
				left: 0;
			}
		}

		&__title {
			padding: 0;
			font-weight: var(--text-weight-normal);
		}
	}
}
