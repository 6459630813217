@use "src/styles/vars.scss" as *;

.app-subscribe-card {
	padding: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(4px);

	@include max-width($scr-sc) {
		padding: 30px 20px;
	}

	@mixin overallYPadding($paddingTop, $paddingBottom) {
		padding-top: $paddingTop;
		padding-bottom: $paddingBottom;
	}

	@mixin overallXPadding($paddingLeft, $paddingRight) {
		padding-left: $paddingLeft;
		padding-right: $paddingRight;
	}

	.subscribe-card {
		width: 100%;
		max-width: 1300px;
		outline: none;
		position: relative;
		border-radius: 16px;
		background-color: var(--c-pagination-highlight-1);
		z-index: 99999;
		transition: opacity 0.5s ease, transform 0.5s ease;

		@include overallYPadding(40px, 60px);

		@include max-width($scr-mm) {
			overflow-y: scroll;
			@include overallYPadding(0, 30px);

			.subscribe-card__img-wrapper {
				@include overallXPadding(0, 0);
			}

			.subscribe-card__header {
				@include overallYPadding(0, 0);
			}

			.subscribe-card__wrapper {
				@include overallXPadding(30px, 30px);
			}

			.input__input {
				padding: 8px;

				&::placeholder {
					font-size: var(--text-size-s);
				}
			}
		}

		@include max-width($scr-xc) {
			.subscribe-card__wrapper {
				@include overallXPadding(20px, 20px);
			}
		}

		&--visible {
			animation: fadeInScale 0.4s ease;
		}

		&__header {
			display: flex;
			justify-content: flex-end;

			@include overallYPadding(0, 40px);
			@include overallXPadding(60px, 60px);
		}

		&__close-btn {
			width: 30px;
			height: 30px;
			cursor: pointer;

			@include max-width($scr-mm) {
				position: absolute;
				top: 20px;
				right: 20px;
				width: 25px;
				height: 25px;
				padding: 5px;
				border-radius: 15px;
				background-color: var(--c-mid-grey);
			}
		}

		&__container {
			color: var(--c-white);
			display: flex;
			margin: 0 auto;
			gap: 60px;

			@include max-width($scr-mm) {
				align-items: stretch;
				flex-direction: column;
				gap: 20px;
			}
		}

		&__img-wrapper {
			min-width: 0;

			@include overallXPadding(60px, 0);

			@include max-width($scr-lg) {
				flex: 1;
			}
		}

		&__img {
			width: 100%;
			max-width: 500px;
			height: 100%;
			max-height: 420px;
			object-fit: cover;
			border-radius: 10px;
			display: block;

			@include max-width($scr-mm) {
				border-radius: 10px 10px 0 0;
				max-width: 100%;
				height: 200px;
			}
		}

		&__wrapper {
			display: flex;
			flex-direction: column;
			flex: 1;
			min-width: 0;

			@include overallXPadding(0, 60px);
		}

		&__title {
			font-size: var(--title-font-size);
			font-weight: var(--text-weight-semibold);
			line-height: normal;
			margin-bottom: 20px;
			white-space: wrap;

			@include max-width($scr-mm) {
				font-size: var(--text-size-l);
			}
		}

		&__description {
			font-size: calc(var(--text-size-m));
			color: var(--c-grey);
			margin: 0 0 40px;

			@include max-width($scr-mm) {
				margin-bottom: 20px;
				font-size: calc(var(--text-size-body));
			}
		}

		&__form-wrapper {
			.app-input {
				width: 100%;

				@include max-width($scr-mm) {
					min-width: 0;
				}

				.input {
					width: 100%;

					&__label {
						margin-bottom: 0;
						width: 100%;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;

						@include max-width($scr-mm) {
							font-size: calc(var(--text-size-s));
						}
					}
				}
			}

			.terms-section {
				display: flex;
				flex-direction: column;
				width: 100%;
				margin-top: 30px;

				@include max-width($scr-mm) {
					margin-top: 20px;
				}

				.app-checkbox {
					width: 100%;

					.checkbox {
						&__wrapper {
							width: 100%;
							align-items: center;
						}
					}
				}

				.app-button {
					margin-top: 16px;
					width: 100%;

					@include max-width($scr-mm) {
						padding: 10px 20px;
					}
				}

				a {
					color: var(--c-grey);
				}
			}
		}
	}
}

@keyframes fadeInScale {
	0% {
		opacity: 0;
		transform: scale(0.8);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
