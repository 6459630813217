@use "src/styles/vars.scss" as *;

.atf-chatbot-button {
	position: fixed;
	bottom: 20%;
	right: 3%;
	width: 60px;
	height: 60px;
	border: 0;
	border-radius: 100px;
	background-position: center;
	background-repeat: no-repeat;
	background-color: var(--c-grey);
	background-image: url("../assets/images/pages/page-home/chatbot-icon.svg");
	z-index: 1000;

	&--active {
		top: auto;
		background-size: 30px 30px;
		background-color: var(--theme-chatbot-btn);
		background-image: url("../assets/images/close-outline-icon.svg");
	}

	@include max-width($scr-xs) {
		bottom: 15%;
	}
}

.atf-chatbot {
	&__iframe {
		position: fixed;
		width: 350px;
		min-width: 285px;
		height: 80vh;
		right: 8%;
		bottom: 10%;
		z-index: 1000;
		border-radius: 10px;
		display: none;
		opacity: 0;
		transform: scale(0.95);
		transform: translateY(100%);
		animation: visible 0.5s forwards;

		@include max-width($scr-xs) {
			width: 100%;
			height: 100%;
			top: 0;
			bottom: 0;
			right: 0;
			border-radius: 0;
		}

		@include min-width($scr-sc) {
			right: 12%;
		}

		@include min-width($scr-md) {
			right: 10%;
		}

		@include min-width($scr-lg) {
			right: 8%;
		}
	}

	&--active {
		.atf-chatbot__iframe {
			display: block;
			z-index: 1200;
		}

		.atf-chatbot__close {
			display: block;

			@include min-width($scr-xs) {
				display: none;
			}
		}
	}

	&__close {
		content: "";
		position: fixed;
		top: 0;
		right: 0;
		width: 50px;
		height: 50px;
		border: 0;
		z-index: 99999999999;
		background-size: 30px 30px;
		background-position: center;
		background-repeat: no-repeat;
		background-color: transparent;
		background-image: url("../assets/images/close-outline-icon.svg");
		display: none;

		@include max-width($scr-xs) {
			top: 5px;
			right: 5px;
			width: 25px;
			height: 25px;
		}
	}
}

@keyframes visible {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

//scroll top
.scroll-top-button {
	display: none;
	position: fixed;
	bottom: 20%;
	right: 3%;
	width: 60px;
	height: 60px;
	border: 1px solid var(--c-border-grey);
	border-radius: 11px;
	background-position: center;
	background-repeat: no-repeat;
	background-color: var(--c-dark-black);
	background-image: url("../assets/images/pages/page-home/arrow-top.svg");
	z-index: 1000;

	&:hover{
		background-color: var(--c-dark-grey);
	}

	@include max-width($scr-xs) {
		bottom: 5%;
		display: block;
	}
}
