@use "src/styles/vars.scss" as *;

.app-article-details {
	height: 100%;
	border-radius: 10px;
	border: 1px solid var(--c-dark-grey);
	background: var(--c-box-grey);

	.article-details {
		padding: 40px;

		@include max-width($scr-sm) {
			overflow-y: auto;
			max-height: 580px;
            mask-image: linear-gradient(to bottom, 
                black 0%, 
                black calc(100% - 50px), 
                transparent 100%
            );
            -webkit-mask-image: linear-gradient(to bottom, 
                black 0%, 
                black calc(100% - 50px), 
                transparent 100%
            );
		}

		@include max-width($scr-xc) {
			padding: 30px;
		}

		&__content {
			padding-top: 40px;

			@include max-width($scr-xc) {
				padding-top: 30px;
			}

			&--first {
				padding: 0 0 40px 0;
				border-bottom: 1px solid var(--c-grey);

				@include max-width($scr-xc) {
					padding: 0 0 30px 0;
				}
			}
		}

		&__title {
			margin: 0 0 24px;
			color: var(--theme-text-color);
			overflow-wrap: break-word;
			font-weight: var(--text-weight-bold);
			font-size: var(--text-size-l);

			@include max-width($scr-xc) {
				margin-bottom: 10px;
				font-size: var(--text-size-s);
			}
		}

		&__description {
			margin: 0;
			color: var(--theme-text-color-2nd);
			overflow-wrap: break-word;
			font-size: calc(var(--text-size-body));
			font-weight: var(--text-weight-regular);

			@include max-width($scr-xc) {
				font-size: var(--text-size-xs);
			}

			p {
				margin: 0;
			}

			p:not(:last-child) {
				padding-bottom: 30px;
			}

			li {
				padding: 5px 0;

				&:last-child {
					padding: 5px 0 0;
				}
			}
		}
	}
}
