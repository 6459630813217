@use "src/styles/vars.scss" as *;

.page-all-articles {
	scroll-behavior: smooth;
	background-color: var(--theme-background-color);

	.MuiSelect-icon {
		margin-right: 10px;
	}

	.articles {
		height: 100%;

		.articles-section {
			@include sectionMarginAndPadding;
			background-color: var(--theme-background-color);

			@include max-width($scr-xc) {
				margin: 30px 0;
			}

			&__title {
				@include titleFont;
				margin: 0;
				text-align: left;
				color: var(--theme-text-color);
				font-weight: var(--text-weight-bold);
			}

			&__header {
				padding-bottom: 40px;
				gap: 20px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				@include max-width($scr-xc) {
					padding-bottom: 20px;
				}
			}

			&__wrapper {
				display: flex;
				flex-wrap: wrap;
				gap: 30px;

				@include max-width($scr-xc) {
					gap: 20px;
				}
			}

			&__pagination {
				display: flex;
				justify-content: center;
				margin-top: 40px;

				@include max-width($scr-xc) {
					margin-top: 30px;
				}
			}
		}

		.tabs-section {
			@include sectionMarginAndPadding;

			@include max-width($scr-xc) {
				margin: 30px 0;
				.MuiTab-root {
					min-width: 120px;
				}
			}
		}
	}
}
