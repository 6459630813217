@use "src/styles/vars.scss" as *;

.app-office-card {
	flex: 0 1 calc(33.33% - 13.33px);

	@include max-width($scr-lg) {
		flex: 0 1 calc(50% - 10px);
	}

	@include max-width($scr-sm) {
		flex: 0 1 100%;
	}

	.office-card {
		display: flex;
		padding: 35px;
		flex-direction: column;
		justify-content: space-between;
		border-radius: 8px;
		background-color: var(--c-button-black);
		border: 1px solid var(--c-dark-grey);
		color: var(--c-white);
		min-height: 415px;

		@include max-width($scr-xc) {
			padding: 30px;
			min-height: 200px;
		}

		@include max-width($scr-xx) {
			padding: 30px 20px;
			min-height: 280px;
		}

		&__icon {
			margin-right: 5px;
			font-size: 1.2rem;
			vertical-align: middle;

			@include max-width($scr-xc) {
				height: 16.55px;
				width: 16.55px;
			}
		}

		&__title {
			font-size: var(--text-size-s);
			font-weight: var(--text-weight-regular);
			margin-bottom: 10px;
			margin-top: 10px;

			@include max-width($scr-xc) {
				margin-top: 0px;
			}
		}

		&__location {
			font-size: var(--text-size-m);
			font-weight: var(--text-weight-bold);
			margin-bottom: 10px;

			@include max-width($scr-xc) {
				font-size: var(--text-size-body);
			}
		}

		&__address {
			font-size: var(--text-size-s);
			margin-bottom: 30px;
			color: var(--theme-text-color-2nd);

			@include max-width($scr-xx) {
				margin-bottom: 10px;
			}

			@include max-width($scr-xc) {
				margin-bottom: 20px;
				font-size: var(--text-size-xs);
			}
		}

		&__text {
			margin: 0;
		}

		&__email {
			font-size: var(--text-size-s);
			margin-bottom: 20px;

			display: flex;
			align-items: center;
			margin-top: auto;
			border-radius: 32px;
			width: fit-content;
			border: 1px solid var(--c-dark-grey);
			background-color: var(--c-bg-dark);
			padding: 10px 20px;

			@include max-width($scr-xc) {
				font-size: var(--text-size-xs);
			}

			a {
				color: var(--c-pagination-white);
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		&__button {
			margin: 20px 0 0 0;
			display: inline-block;
			padding: 10px 20px;
			border-radius: 4px;
			background-color: var(--theme-highlight);
			color: var(--c-white);
			text-decoration: none;
			text-align: center;
			font-weight: var(--text-weight-normal);

			&:hover {
				text-decoration: underline;
			}

			@include max-width($scr-xc) {
				margin: 0;
				font-size: var(--text-size-xs);
			}
		}
	}
}
