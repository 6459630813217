@use "src/styles/vars.scss" as *;

.app-header-card {
	width: 100%;
	margin-top: 10px;
	background-color: var(--theme-background-color);
	@include contentPadding;

	&--no-bg {
		background-color: transparent;
	}

	&__image {
		padding: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 300px;
		border-radius: 8px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		&--post {
			padding-bottom: 30px;
			align-items: flex-end;
		}

		&--top {
			background-position: top;
		}
	}

	&__title {
		@include titleFont;
		margin: 0;
		display: flex;
		text-align: center;
		justify-content: center;
		align-items: center;
		color: var(--c-white);
		font-weight: var(--text-weight-semibold);
		text-shadow: 4px 4px 15px rgba(0, 0, 0, 1);

		&--large {
			font-size: var(--text-size-xxxxl);
			font-weight: var(--text-weight-bold);
		}
	}

	@include max-width($scr-xc) {
		&__image {
			height: 180px;
		}
	}

	@include max-width($scr-xc) {
		&__title {
			&--large {
				font-size: var(--text-size-xxl);
				font-weight: var(--text-weight-bold);
			}
		}
	}
}
