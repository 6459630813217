@use "src/styles/vars.scss" as *;

.app-work-here-card {
	min-height: 230px;
	border-radius: 10px;
	border: 1px solid var(--c-dark-grey);
	background: linear-gradient(0deg, var(--c-dark-black), var(--c-dark-black));

	@include max-width($scr-xc) {
		min-height: 50px;
	}

	.work-here-card {
		padding: 40px;

		@include max-width($scr-xc) {
			padding: 30px;
		}

		&__header {
			gap: 10px;
			display: flex;
			align-items: center;
		}

		&__wrapper {
			padding: 12px;
			border-radius: 10px;
			border: 1px solid var(--c-border-grey);
			background-color: var(--c-button-black);
			display: flex;
			align-items: center;
			justify-content: center;

			@include max-width($scr-xc) {
				padding: 9.93px;
			}
		}

		&__icon {
			width: 30px;
			height: 30px;
			filter: brightness(0) saturate(100%) invert(1) sepia(1) saturate(0) hue-rotate(var(--theme-highlight));

			@include max-width($scr-xc) {
				width: 24.83px;
				height: 24.83px;
			}
		}

		&__title {
			margin: 0;
			color: var(--c-white);
			overflow-wrap: break-word;
			font-weight: var(--text-weight-bold);
			font-size: calc(var(--text-size-m) + 2px);
			display: -webkit-box;

			@include max-width($scr-xc) {
				font-size: var(--text-size-s);
			}
		}

		&__description {
			margin: 0;
			padding-top: 24px;
			color: var(--theme-text-color-2nd);
			overflow-wrap: break-word;
			font-size: calc(var(--text-size-body));
			font-weight: var(--text-weight-regular);
			display: -webkit-box;

			@include max-width($scr-xc) {
				padding-top: 10px;
				font-size: var(--text-size-xs);
			}
		}
	}
}
