@use "src/styles/vars.scss" as *;

.app-brochure-card {
	width: 100%;

	.brochure-card {
		&__container {
			padding: 20px;
			border-radius: 10px;
			border: 1px solid var(--c-dark-grey);
			background: linear-gradient(0deg, var(--c-dark-black), var(--c-dark-black));
		}

		&__image {
			width: 100%;
			border-radius: 10px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			aspect-ratio: 9 / 12;
			cursor: pointer;
		}

		&__title {
			margin: 20px 0;
			color: var(--theme-text-color);
			font-size: calc(var(--text-size-m) + 2px);
			font-weight: var(--text-weight-bold);
			text-align: left;
			overflow-wrap: break-word;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			@include max-width($scr-xc) {
				margin: 10px 0;
				font-size: var(--text-size-s);
			}
		}

		&__btn {
			@include max-width($scr-xc) {
				padding: 9.37px 6.7px;
				font-size: var(--text-size-xxxs);
			}
		}
	}
}
