@use "src/styles/vars.scss" as *;

$colors: rgba(48, 105, 178, 1), rgba(187, 206, 229, 1), rgba(255, 255, 255, 1);
$max: length($colors);
$dash: 50;
$dash-gap: 10;
$dash-space: $dash * ($max - 1) + $dash-gap * $max;
$mobile-dash: 200;
$mobile-dash-gap: 20;
$mobile-dash-space: $mobile-dash * ($max - 1) + $mobile-dash-gap * $max;
$time: 3.5s;
$time-step: calc($time / $max);

.app-screen-loader {
	height: 100%;
	width: 100%;

	.screen-loader {
		background-size: 0.2em 100%;
		font: 2.5em/1 Poppins, Impact;
		text-transform: uppercase;
		margin: 0;

		@include max-width($scr-sm) {
			font: 5em/1 Poppins, Impact;
		}

		svg {
			position: absolute;
			stroke-linecap: round;
			width: 100%;
			height: 100%;
		}

		&__text {
			fill: none;
			stroke-width: 2;
			stroke-linejoin: round;
			stroke-dasharray: $dash $dash-space;
			stroke-dashoffset: 0;
			-webkit-animation: stroke $time infinite linear;
			animation: stroke $time infinite linear;

			@include max-width($scr-sm) {
				stroke-width: 3;
				stroke-dasharray: $mobile-dash $mobile-dash-space;
			}

			@for $item from 1 through $max {
				&:nth-child(#{$max}n + #{$item}) {
					$color: nth($colors, $item);
					stroke: $color;
					-webkit-animation-delay: -($time-step * $item);
					animation-delay: -($time-step * $item);
				}
			}
		}
	}
}

@-webkit-keyframes stroke {
	100% {
		stroke-dashoffset: -($dash + $dash-gap) * $max;
	}
}

@keyframes stroke {
	100% {
		stroke-dashoffset: -($dash + $dash-gap) * $max;
	}
}

@include max-width($scr-sm) {
	@-webkit-keyframes stroke {
		100% {
			stroke-dashoffset: -($mobile-dash + $mobile-dash-gap) * $max;
		}
	}

	@keyframes stroke {
		100% {
			stroke-dashoffset: -($mobile-dash + $mobile-dash-gap) * $max;
		}
	}
}
