@use "src/styles/vars.scss" as *;

.app-article-card {
	min-height: 185px;
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-content: space-between;
	transition: transform 0.2s;

	&-wrapper {
		opacity: 0;
		position: relative;
		transition: transform 0.2s;
		flex: 0 1 calc(33.33% - 20px);
		@include max-width($scr-sm) {
			flex: 0 1 calc(50% - 20px);
		}

		@include max-width($scr-xc) {
			flex: 0 1 100%;
		}

		&:hover {
			z-index: 10;
			transform: scale(1.15);
		}

		@for $n from 1 through 20 {
			&:nth-child(#{$n}) {
				animation-delay: calc(0.1s * #{$n - 1});
			}
		}
		
		animation: slideIn 1s ease 0s 1 normal forwards;
	}

	&:hover {
		transform: scale(1.15);

        @include max-width($scr-xc) {
			transform: scale(1.05);
		}
	}

	@keyframes slideIn {
		0% {
			opacity: 0;
			transform: translateY(250px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	.article-card {
		height: 100%;
		width: 100%;
		gap: 10px;
		display: flex;
		flex-direction: column;

		@include max-width($scr-xc) {
			gap: 13px;
		}

		&__image {
			width: 100%;
			height: 185px;
			object-fit: cover;
			border-radius: 10px;
			border: 1px solid var(--c-dark-grey);
			cursor: pointer;

			@include max-width($scr-xc) {
				margin-bottom: 3.5px;
			}
		}

		&__title {
			margin: 0;
			color: var(--theme-text-color);
			overflow-wrap: break-word;
			font-size: var(--text-size-body);
			font-weight: var(--text-weight-bold);

			@include max-width($scr-xc) {
				font-size: var(--text-size-s);
			}
		}

		&__description {
			margin: 0;
			padding-top: 3px;
			overflow-wrap: break-word;
			color: var(--theme-desc-color);
			font-size: var(--text-size-body);
			font-weight: var(--text-weight-regular);

			@include max-width($scr-xc) {
				font-size: var(--text-size-xs);
				padding-top: 0px;
			}
		}
	}
}
