@use "src/styles/vars.scss" as *;

.app-button {
	min-width: 150px;
	padding: 15px 20px;
	background-color: var(--theme-highlight);
	color: var(--c-white);
	font-weight: var(--text-weight-normal);
	border: 0;
	border-radius: 8px;
	cursor: pointer;
	flex-shrink: 0;

	@include max-width($scr-xc) {
		height: 44px;
		padding: 10px 20px;
	}

	.button {
		&__label {
			margin: 0;
		}

		@include max-width($scr-xc) {
			font-size: var(--text-size-s);
		}
	}

	&--small {
		min-width: auto;
		padding: 12.5px 20px;

		.button {
			&__label {
				font-size: var(--text-size-s);
			}
		}
	}

	&--outline {
		background-color: transparent;
		border: 1px solid var(--c-white);

		.button {
			&__label {
				color: var(--c-white);
			}
		}
	}

	&--secondary {
		background-color: var(--c-button-black);
		border: 1px solid var(--c-dark-grey);

		.button {
			&__label {
				color: var(--c-white);
			}
		}
	}

	&--tertiary {
		background-color: var(--c-button-black);
		border: 1px solid var(--c-dark-grey);

		.button {
			&__label {
				color: var(--theme-desc-color);
				font-weight: var(--text-weight-regular);
				font-size: var(--text-size-s);
			}
		}
	}

	&--text {
		background-color: transparent;
	}

	&--uppercase {
		text-transform: uppercase;
	}

	&:disabled {
		opacity: 0.7;
		cursor: not-allowed;
	}

	&:not(:disabled):hover {
		filter: brightness(85%);
	}
}
