@use "src/styles/vars.scss" as *;

.app-company-card {
	border-radius: 10px;
	border: 1px solid var(--c-sky-blue-mist);
	background: linear-gradient(0deg, var(--c-dark-black), var(--c-dark-black));

	.company-card {
		padding: 40px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: space-between;

		@include max-width($scr-xc) {
			padding: 30px;
		}

		&__description {
			margin: 0;
			color: var(--theme-text-color-2nd);
			overflow-wrap: break-word;
			font-size: calc(var(--text-size-body));
			font-weight: var(--text-weight-regular);
			display: block;

			@include max-width($scr-xc) {
				font-size: var(--text-size-s);
			}
		}

		&__number {
			margin: 0;
			color: var(--c-white);
			overflow-wrap: break-word;
			font-weight: var(--text-weight-bold);
			font-size: calc(var(--text-size-xxxxl) + 8px);
			display: flex;
			align-items: center;
			gap: 4px;
		}

		&__title {
			margin: 0;
			padding: 10px 0 10px 0;
			color: var(--c-white);
			overflow-wrap: break-word;
			font-weight: var(--text-weight-bold);
			font-size: calc(var(--text-size-m) + 2px);

			@include max-width($scr-xc) {
				font-size: var(--text-size-s);
			}
		}

		&__section {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			height: 80%;
		}
	}
}
