@use "src/styles/vars.scss" as *;

.app-pagination {
	height: 68px;
	width: fit-content;
	border-radius: 10px;
	background-color: var(--c-dark-black);
	border: 1px solid var(--c-border-grey);

	.pagination {
		padding: 12px;
		display: flex;
		align-items: center;
		justify-content: center;

		&__wrapper {
			padding: 0 10px;
			gap: 3px;
			display: flex;
		}

		.stepper {
			width: 14px;
			height: 4px;
			border-radius: 100px;
			background-color: var(--c-pagination-highlight-1);
			border: 1px solid var(--c-pagination-highlight-1);

			&--active {
				width: 18px;
				border-radius: 100px;
				background-color: var(--theme-highlight);
				border: 1px solid var(--theme-highlight);
			}
		}

		&__container {
			width: 44px;
			height: 44px;
			border-radius: 10px;
			background-color: var(--c-box-grey);
			border: 1px solid var(--c-border-grey);
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		&__icon {
			width: 24px;
			height: 24px;
		}
	}

	@include max-width($scr-xc) {
		height: 45px;

		.pagination {
			height: 45px;
			padding: 7.94px;

			.pagination__container {
				height: 29.12px;
				width: 29.12px;

				.pagination__icon {
					height: 15.88px;
					width: 15.88px;
				}
			}
		}
	}
}
