@use "src/styles/vars.scss" as *;

.app-popup-ads {
	padding: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(4px);

	@include max-width($scr-sc) {
		padding: 30px 20px;
	}

	@mixin overallYPadding($paddingTop, $paddingBottom) {
		padding-top: $paddingTop;
		padding-bottom: $paddingBottom;
	}

	@mixin overallXPadding($paddingLeft, $paddingRight) {
		padding-left: $paddingLeft;
		padding-right: $paddingRight;
	}
	.ads {
		margin: 0 auto;
		width: 100%;
		max-width: 1078px;
		outline: none;
	}

	.headline {
		outline: none;
		padding: 20px;
		text-transform: uppercase;
		overflow-wrap: break-word;
		word-break: break-word;
		text-align: center;
		color: var(--c-bright-yellow);
		font-size: var(--text-size-xl);
		font-weight: var(--text-weight-semibold);

		@include max-width($scr-sm) {
			padding: 8px 15px;
			font-size: var(--text-size-m);
		}

		@include max-width($scr-xx) {
			padding: 8px;
			font-size: var(--text-size-body);
		}
	}

	.popup-ads {
		width: 100%;
		height: 100%;
		overflow: hidden;
		outline: none;
		position: relative;
		border-radius: 16px;
		border: 1px solid var(--c-black);
		background-color: var(--c-pure-black);
		z-index: 99999;
		transition: opacity 0.5s ease, transform 0.5s ease;

		@include max-width($scr-sm) {
			max-height: 70vh;
			height: auto;

			.popup-ads__img-wrapper {
				@include overallXPadding(0, 0);
			}

			.popup-ads__header {
				@include overallYPadding(0, 0);
			}

			.popup-ads__wrapper {
				@include overallXPadding(30px, 30px);
			}
		}

		@include max-width($scr-xc) {
			.popup-ads__wrapper {
				@include overallXPadding(20px, 20px);
			}
		}

		&--visible {
			animation: fadeInScale 0.4s ease;
		}

		&__btn-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;

			@include overallYPadding(30px, 20px);

			@include max-width($scr-xc) {
				@include overallYPadding(20px, 20px);
			}
		}

		&__enter-btn {
			width: 100%;
			max-width: 311px;
			cursor: pointer;
			background-color: transparent;
			border: 0;
			font-size: calc(var(--text-size-l) + 2px);
			font-weight: var(--text-weight-normal);
			display: flex;
			align-items: center;
			justify-content: space-between;
			text-shadow: 0 0 10px rgba(24, 235, 246, 0.5), 0 0 20px rgba(24, 235, 246, 0.5);

			.enter-symbol {
				color: var(--theme-highlight);
				white-space: nowrap;
			}

			.enter-text {
				color: var(--c-white);
				white-space: nowrap;
				flex-shrink: 1;
			}

			@include max-width($scr-sm) {
				max-width: 260px;
				font-size: var(--text-size-m);
				font-weight: var(--text-weight-normal);
			}
		}

		&__container {
			margin: 0 auto;
			color: var(--c-white);
			display: flex;
			gap: 30px;
			max-height: 500px;

			@include max-width($scr-mm) {
				align-items: stretch;
				flex-direction: column;
			}

			@include max-width($scr-xs) {
				align-items: stretch;
				flex-direction: column;
				gap: 20px;
			}

			@include max-width($scr-xc) {
				align-items: stretch;
				flex-direction: column;
				gap: 20px;
			}

			@include max-width($scr-xxx) {
				gap: 10px;
				overflow-y: scroll;
				height: 100%;
				max-height: 490px;
			}
		}

		&__img-wrapper {
			flex: 1;

			@include max-width($scr-lg) {
				flex: 1;
			}
		}

		&__img {
			width: 100%;
			border-radius: 16px 0 0 16px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			aspect-ratio: 1;

			@include max-width($scr-mm) {
				border-radius: 10px 10px 0 0;
				height: 230px;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}

			@include max-width($scr-xc) {
				border-radius: 10px 10px 0 0;
				height: 220px;
				background-size: 100% 100%;
			}
		}

		&__wrapper {
			flex: 1;
			min-width: 0;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			@include overallXPadding(0, 30px);

			@include max-width($scr-mm) {
				@include overallXPadding(30px, 30px);
			}
		}

		&__title {
			margin-bottom: 20px;
			word-break: break-word;
			color: var(--c-white);
			font-size: 42px;
			font-weight: var(--text-weight-semibold);

			@include max-width($scr-mm) {
				font-size: var(--text-size-xl);
			}

			@include max-width($scr-sm) {
				font-size: 24px;
			}

			@include max-width($scr-xc) {
				margin-bottom: 10px;
			}
		}

		&__description {
			word-break: break-word;
			color: var(--c-grey);
			font-size: var(--text-size-m);
			font-weight: var(--text-weight-normal);

			@include max-width($scr-mm) {
				font-size: calc(var(--text-size-s));
			}
		}

		&__steppers {
			display: flex;
			justify-content: center;
			padding: 10px 0;

			.app-pagination {
				width: 100%;
				background-color: transparent;
				border: none;
				display: flex;
				align-items: flex-end;

				.pagination {
					padding: 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
				}
			}
		}
	}
}

@keyframes fadeInScale {
	0% {
		opacity: 0;
		transform: scale(0.8);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
