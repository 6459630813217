.app-checkbox {
	.checkbox {
		display: flex;
		flex-direction: column;

		&__wrapper {
			gap: 10px;
			display: flex;
			align-items: center;
		}

		&__required {
			color: var(--theme-highlight);
			font-size: var(--text-size-xs);
			font-weight: var(--text-weight-semibold);
			vertical-align: top;
		}

		&__error {
			margin: 10px 10px 0 0;
			color: var(--c-error-message);
			font-size: var(--text-size-xs);
			word-break: break-word;
		}

		&__button {
			width: 22px;
			height: 22px;
			padding: 4px;
			border-radius: 5px;
			background-color: transparent;
			border: 1px solid var(--c-dark-grey);
			background-color: var(--c-box-grey);
			transition: var(--transition);
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				opacity: 0;
				transition: var(--transition);
			}

			&:disabled {
				background-color: var(--c-disabled);

				img {
					path {
						stroke: var(--c-grey);
					}
				}
			}
		}

		&__label {
			margin: 0 10px;
			color: var(--theme-text-color);
			font-size: var(--text-size-s);
			font-weight: var(--text-weight-semibold);
		}
	}

	&--checked {
		.checkbox {
			&__button {
				img {
					opacity: 1;
				}
			}
		}
	}
}
