@use "src/styles/vars.scss" as *;

.app-slideshow {
	//
	.marquee-logo {
		//
		&__img {
			margin: 0 20px;
		}
	}

	.marquee-career {
		//
		&__img {
			height: 400px;
			margin: 0 5px;
			padding: 5px 0;

			@include max-width($scr-xs) {
				height: 300px;
			}
		}
	}
}
