@use "src/styles/vars.scss" as *;

.app-navbar {
	width: 100%;
	background-color: var(--theme-background-color);
	position: sticky;
	top: 0;
	z-index: 1100;
	overflow-x: hidden;
	transition: 0.3s ease;

	&__fixed {
		position: fixed;
		background-color: var(--c-nav-grey);

		.nav-menu__item {
			color: var(--c-white) !important;
		}

		&--active {
			position: fixed;
			background-color: var(--theme-background-color);
		}
	}

	.navbar {
		padding: 0;

		&__wrapper {
			width: 100%;
			padding: 25px 8% 25px;

			@include max-width($scr-sm) {
				padding: 20px 8% 20px;
			}

			@include max-width($scr-xc) {
				padding: 20px 5% 20px;
			}
		}

		&__container {
			display: flex;
			justify-content: space-between;
			align-items: center;

			img {
				height: 48px;
			}
		}

		.nav-menu {
			gap: 30px;
			display: flex;

			&__item {
				margin: 0;
				font-weight: 300;
				text-decoration: none;
				color: var(--theme-text-color);
				font-size: var(--text-size-body);
			}

			&__label {
				font-weight: var(--text-weight-normal);
				margin: 0;

				&--active {
					color: var(--theme-highlight);
				}
			}

			@include max-width($scr-sm) {
				display: none;
			}
		}
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
}

.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1002;
	width: 100%;
	height: 100vh;
	transform: translateX(-100%);
	transition: transform 0.3s ease-in-out;

	&-open {
		transform: translateX(0);
	}

	&-header {
		display: flex;
		justify-content: space-between;
	}

	&-logo {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
	}

	&-menu-btn {
		@include min-width($scr-sm) {
			display: none;
		}
		height: 20px !important;
		width: 28px;
	}

	&-close-btn {
		height: 20px;
		width: 20px;
	}

	&-content {
		overflow-y: auto;
		padding: 26px 30px;
		height: 100%;
		background-color: var(--c-sidenav-bg);
		display: grid;
		grid-template-rows: auto 1fr auto;
	}

	&-nav {
		padding: 2rem 0 0 0;
		margin-top: 1.25rem;
		border-top: 1px solid var(--c-sidenav-border);

		.nav-item {
			margin-left: -1rem;
			margin-right: -1rem;
			margin-bottom: 0.5rem;
			list-style: none;
		}

		.nav-link {
			display: flex;
			align-items: center;
			padding: 0.5rem 0.5rem 0.5rem 1rem;
			font-size: 20px;
			font-weight: var(--text-weight-normal);
			color: var(--c-white) !important;
			text-decoration: none;
			border-radius: 0.5rem;
			transition: background-color 0.2s;

			&:hover {
				background-color: var(--c-sidenav-border);
			}
		}
	}
}

// Footer styles
.sidebar-footer {
	display: flex;
	justify-content: center;
	padding: 0 0 60px;
	gap: 1rem;
	width: 100%;
	z-index: 20;

	&-social {
		display: flex;
		gap: 10px;
	}

	&-content {
		width: 100%;
		padding-top: 30px;
		border-top: 1px solid var(--c-darker-grey);

		.footer-address {
			display: flex;
			flex-direction: column;
			flex: 0 1 20%;
			gap: 20px;

			&__title {
				margin: 0;
				color: var(--theme-text-color);
				font-size: var(--text-size-m);
				font-weight: var(--text-weight-semibold);
			}

			&__description {
				margin: 0;
				color: var(--theme-text-color-2nd);
				overflow-wrap: break-word;
				font-size: var(--text-size-s);
				font-weight: var(--text-weight-normal);
			}

			&__address {
				margin: 0;
			}
		}

		.footer-link {
			&__container {
				margin: 0;
				padding: 0;
				width: 44px;
				height: 44px;
				border-radius: 6px;
				background-color: var(--c-box-grey);
				border: 1px solid var(--c-dark-grey);
			}

			&__icon {
				width: 20px;
				height: 20px;
			}

			&__transparent-container {
				position: relative;
				border: 0;
				padding: 0 20px;
				background-color: transparent;

				&:not(:last-child)::after {
					content: "";
					height: 100%;
					width: 1px;
					position: absolute;
					right: 0;
					top: 0;
					background-color: var(--c-dark-grey);
				}

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}
			}

			&__text {
				margin: 0;
				color: var(--theme-text-color-2nd);
				font-size: var(--text-size-s);
				font-weight: var(--text-weight-regular);
			}
		}
	}
}
