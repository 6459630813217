@use "src/styles/vars.scss" as *;

.app-article-info {
	height: auto;
	border-radius: 10px;
	border: 1px solid var(--c-dark-grey);
	background: var(--c-box-grey);

	.article-info {
		padding: 40px;
		@include max-width($scr-mm) {
			padding: 30px 40px;
		}

		@include max-width($scr-xc) {
			padding: 30px;
		}

		&__header,
		&__subheader {
			display: flex;
			gap: 40px;
			overflow: hidden;
			padding-bottom: 40px;
			border-bottom: 1px solid var(--c-grey);

			@include max-width($scr-mm) {
				padding-bottom: 25px;
			}

			@include max-width($scr-xs) {
				gap: 10px;
				padding-bottom: 25px;
			}
		}

		&__header {
			border-bottom: none;
			padding-bottom: 0;
		}

		&__block {
			width: 50%;
			flex-direction: column;
			align-items: flex-start;

			@include max-width($scr-lg) {
				width: 100%;
				display: flex;
				justify-content: space-between;
			}
		}

		&__section {
			padding-top: 24px;
			width: 50%;
			flex-direction: column;
			align-items: flex-start;

			@include max-width($scr-lg) {
				width: 100%;
				display: flex;
				justify-content: space-between;
			}

			@include max-width($scr-xc) {
				padding-top: 20px;
			}
		}

		&__content {
			padding-top: 40px;
			overflow: hidden;

			@include max-width($scr-mm) {
				padding-top: 25px;
			}
		}

		&__description {
			padding-top: 10px;
			margin: 0;
			color: var(--theme-text-color);
			overflow-wrap: break-word;
			font-weight: var(--text-weight-regular);
			font-size: var(--text-size-body);

			@include max-width($scr-mm) {
				padding-top: 12px;
			}

			@include max-width($scr-xc) {
				padding-top: 10px;
				font-size: var(--text-size-xs);
			}
		}

		&__description li {
			margin-left: 0;
			padding-left: 0;
		}

		&__description li:not(:last-child) {
			padding-bottom: 24px;

			@include max-width($scr-xc) {
				padding-bottom: 10px;
			}
		}

		&__title {
			margin: 0;
			color: var(--theme-desc-color);
			overflow-wrap: break-word;
			font-size: var(--text-size-body);
			font-weight: var(--text-weight-regular);

			@include max-width($scr-xc) {
				font-size: var(--text-size-xs);
			}
		}

		&__cta {
			margin-top: 40px;
		}
	}
}
