@use "src/styles/vars.scss" as *;

.app-services-card {
	min-height: 100px;
	max-height: 94px;
	border-radius: 10px;
	border: 1px solid var(--c-dark-grey);
	background: linear-gradient(0deg, var(--c-dark-black), var(--c-dark-black));
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-content: space-between;
	flex: 0 1 calc(33.33% - 20px);

	@include max-width($scr-xm) {
		flex: 0 1 calc(50% - 20px);
	}

	@include max-width($scr-sm) {
		flex: 0 1 100%;
	}

	.services-card {
		padding: 20px;
		height: 94px;
		width: 100%;

		&__header {
			gap: 12px;
			display: flex;
			align-items: center;
		}

		&__wrapper {
			padding: 12px;
			border-radius: 10px;
			border: 1px solid var(--c-border-grey);
			background-color: var(--c-button-black);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__icon {
			width: 30px;
			height: 30px;
		}

		&__title {
			margin: 0;
			color: var(--c-white);
			overflow-wrap: break-word;
			font-weight: var(--text-weight-bold);
			font-size: var(--text-size-body);
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;

			&--bracket {
				font-weight: var(--text-weight-thin);
			}
		}
	}
}
