@use "src/styles/vars.scss" as *;

.app-project-info {
	display: flex;
	position: relative;
	padding: 20px 8% 60px;
	background-color: var(--theme-background-color);
	gap: 20px;

	&__left {
		width: 37%;
		@include max-width($scr-mm) {
			width: 100%;
			margin-bottom: 30px;
		}
	}

	&__right {
		width: 63%;
		@include max-width($scr-mm) {
			width: 100%;
		}
	}

	@include max-width($scr-mm) {
		display: block;
	}

	@include max-width($scr-xc) {
		padding: 20px 5% 60px;
	}

	.project-info {
		height: 100%;
		padding: 40px;
		background: linear-gradient(0deg, var(--c-dark-black), var(--c-dark-black));
		border: 1px solid var(--c-dark-grey);
		border-radius: 10px;
		overflow: hidden;

		@include max-width($scr-mm) {
			padding: 30px 40px;
		}

		&__header {
			padding-bottom: 40px;
			display: flex;
			gap: 40px;
			border-bottom: 1px solid var(--c-grey);

			@include max-width($scr-mm) {
				padding-bottom: 25px;
			}

			@include max-width($scr-xs) {
				gap: 10px;
			}
		}

		&__block {
			width: 50%;
		}

		&__content {
			padding-top: 40px;

			@include max-width($scr-mm) {
				padding-top: 25px;
			}
		}

		&__description {
			padding-top: 24px;
			margin: 0;
			color: var(--c-white);
			font-weight: var(--text-weight-semibold);
			font-size: var(--text-size-body);
			display: flex;
			flex-wrap: wrap;
			overflow: hidden;

			@include max-width($scr-mm) {
				padding-top: 12px;
			}

			&-text {
				margin: 0;
				white-space: normal;
			}

			&-text:not(:last-child)::after {
				content: " | ";
				margin: 0 4px;
			}
		}

		&__title {
			margin: 0;
			color: var(--theme-text-color-2nd);
			font-size: var(--text-size-body);
			font-weight: var(--text-weight-normal);
			display: flex;
			align-items: center;
			gap: 2px;
		}

		&__genres {
			padding: 10px 0 20px 0;
			gap: 10px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-start;

			&-text {
				width: fit-content;
				height: 33px;
				padding: 6px 12px;
				margin: 0;
				color: var(--c-white);
				font-size: var(--text-size-s);
				font-weight: var(--text-weight-normal);
				border-radius: 6px;
				border: 1px solid var(--c-dark-grey);
				background-color: var(--c-button-black);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.project-desc {
		height: 100%;
		padding: 40px;
		background: linear-gradient(0deg, var(--c-dark-black), var(--c-dark-black));
		border: 1px solid var(--c-dark-grey);
		border-radius: 10px;

		&__title {
			margin: 0;
			color: var(--c-white);
			font-weight: var(--text-weight-bold);
			font-size: calc(var(--text-size-m) + 2px);
		}

		&__content {
			margin: 0;
			padding-top: 24px;
			color: var(--theme-text-color-2nd);
			font-size: calc(var(--text-size-body));
			font-weight: var(--text-weight-regular);
		}
	}
}
