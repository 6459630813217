* {
	box-sizing: border-box;
}

#root {
	height: 100%;
	width: 100%;
	background-color: var(--c-bg-black);
}

html,
body {
	height: 100%;
}

body {
	overflow-y: scroll !important;
	color: var(--c-black);
	line-height: 1.2;
	font-size: var(--text-size-body);
	font-family: "Roboto", sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}
