@use "src/styles/vars.scss" as *;

.app-file-upload-field {
	min-width: 250px;

	.file-upload {
		&__form-control {
			width: 100%;
		}

		&__required {
			color: var(--theme-highlight);
			font-size: var(--text-size-xs);
			font-weight: var(--text-weight-semibold);
			vertical-align: text-top;

			@include max-width($scr-xs) {
				font-size: var(--text-size-xxs);
			}
		}

		&__label {
			margin-bottom: 10px;
			width: 100%;
			color: var(--theme-text-color);
			font-size: var(--text-size-body);
			font-weight: var(--text-weight-semibold);

			@include max-width($scr-xs) {
				margin-bottom: 5.42px;
				font-size: var(--text-size-xs);
			}
		}

		&__input {
			display: none;
		}

		&__textfield {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			.MuiOutlinedInput-root {
				input {
					color: var(--theme-placeholder) !important;
					-webkit-text-fill-color: var(--theme-placeholder) !important;
				}
			}

			.MuiOutlinedInput-notchedOutline {
				border: none !important;
			}

			.MuiInputBase-input {
				padding: 16.5px 10px 16.5px 0;
				width: 100%;
				color: var(--theme-placeholder) !important;

				@include max-width($scr-xs) {
					padding: 0 10px 16.5px 0;
					font-size: var(--text-size-xxs);
				}
			}
		}

		&__file-uploaded {
			.MuiOutlinedInput-root {
				input {
					color: var(--c-white) !important;
					-webkit-text-fill-color: var(--c-white) !important;

					@include max-width($scr-xs) {
						padding: 16.5px 10px 16.5px 0;
					}
				}
			}
		}

		&__button-label {
			margin-left: auto;
		}

		&__filename {
			color: var(--c-white) !important;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			width: 100%;
		}

		&__button {
			background-color: var(--c-button-black);
			color: var(--c-white);
			padding: 10px 30px;
			border-radius: 8px;
			border: 1px solid var(--c-dark-grey);
			font-size: var(--text-size-s);
			text-transform: none;
			min-width: 135px;

			@include max-width($scr-xs) {
				min-width: 100px;
				padding: 5.42px 12.67px;
				font-size: var(--text-size-xxs);
			}

			&:hover,
			&:focus,
			&:active {
				background-color: var(--c-button-black);
				color: var(--c-white);
				border-color: var(--c-dark-grey);
				box-shadow: none;
			}
		}

		.file--disabled {
			opacity: 0.6;
			pointer-events: none;
		}

		&__icon {
			cursor: pointer;
		}

		&__body {
			padding: 16px 20px;
			height: 96px;
			border-radius: 6px;
			border: 1px solid var(--c-border-grey);
			background-color: var(--c-box-grey);
			transition: var(--transition);
			display: flex;
			align-items: center;
			position: relative;

			@include max-width($scr-xs) {
				height: 52.22px;
				padding: 8.68px 10.85px;
			}

			&--error {
				border: 1px solid var(--c-error-message) !important;
			}

			&:focus-within {
				border: 1px solid var(--c-black);
			}
		}

		&__footer {
			margin: 7px 0 20px;
		}

		&__info {
			margin: 0;
			font-size: calc(var(--text-size-body) - 1px);
			font-weight: var(--text-weight-semibold);
			color: var(--c-darker-grey);

			@include max-width($scr-xs) {
				color: #E6E6E6;
				text-align: center;
				font-size: var(--text-size-xxs);
			}

		}

		&__error {
			margin: 10px 10px 0 0;
			color: var(--c-error-message);
			word-break: break-word;
			font-size: var(--text-size-xs);
			display: flex;

			@include max-width($scr-xs) {
				place-content: center;
				font-size: var(--text-size-xxs);
			}
		}
	}
}
