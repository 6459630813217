@use "src/styles/vars.scss" as *;

.page-career {
	scroll-behavior: smooth;
	background-color: var(--theme-background-color);

	.career {
		.about-work {
			@include sectionMarginAndPadding;

			@include max-width($scr-xc) {
				margin: 30px 0 60px;
			}

			&__title {
				@include titleFont;
				margin: 0;
				padding-bottom: 10px;
				color: var(--theme-text-color);
				font-weight: var(--text-weight-bold);
			}

			&__description {
				max-width: calc(100% - 200px);
				margin: 0;
				color: var(--theme-text-color);
				font-size: var(--text-size-m);
				font-weight: var(--text-weight-thin);

				@include max-width($scr-md) {
					max-width: calc(100%);
				}

				@include max-width($scr-xc) {
					max-width: calc(100%);
					font-size: var(--text-size-s);
				}
			}

			&__wrapper {
				margin-top: 60px;
				display: grid;
				grid-gap: 20px;
				grid-template-columns: repeat(3, 1fr);

				@include max-width($scr-md) {
					grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
				}

				@include max-width($scr-xc) {
					margin-top: 40px;
					grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
				}
			}
		}

		.vacancy {
			@include sectionMarginAndPadding;
			display: flex;
			flex-direction: column;

			@include max-width($scr-xc) {
				margin: 60px 0;
			}

			&__title {
				@include titleFont;
				margin: 0;
				padding-bottom: 10px;
				color: var(--theme-text-color);
				font-weight: var(--text-weight-semibold);
			}

			&__description {
				margin: 0;
				color: var(--theme-text-color-2nd);
				font-size: var(--text-size-m);
				font-weight: var(--text-weight-normal);

				@include max-width($scr-xc) {
					font-size: var(--text-size-s);
				}
			}

			&__selected {
				padding-top: 40px;
				display: flex;
				flex-wrap: wrap;
				gap: 30px;
				justify-content: space-between;

				@include max-width($scr-xc) {
					padding-top: 20px;
				}
			}

			&__option {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: 30px;

				@include max-width($scr-xs) {
					gap: 20px;

					.app-select-input {
						.select-input__label,
						.select-input__input,
						.select-options__filter-input,
						.select-options__fetching,
						.select-options__button {
							font-size: var(--text-size-s);
						}

						.select-input__chevron-icon > img {
							height: 100%;
							width: 100%;
						}

						.select-input__body {
							height: auto;
							padding: 12px;
						}
					}
				}
			}

			&__wrapper {
				padding-top: 60px;
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 20px;

				@include max-width($scr-md) {
					grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
				}

				@include max-width($scr-xs) {
					padding-top: 40px;
					grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
				}
			}
		}

		.form-section {
			@include sectionMarginAndPadding;

			@include max-width($scr-xs) {
				margin: 60px 0 40px;
			}

			&__title {
				@include titleFont;
				margin: 0;
				padding-bottom: 10px;
				color: var(--theme-text-color);
				font-weight: var(--text-weight-semibold);
			}

			&__description {
				max-width: calc(100% - 200px);
				margin: 0;
				color: var(--theme-text-color-2nd);
				font-size: var(--text-size-m);
				font-weight: var(--text-weight-normal);

				@include max-width($scr-sm) {
					max-width: 100%;
				}

				@include max-width($scr-xs) {
					max-width: 100%;
					font-size: var(--text-size-s);
				}
			}

			&__wrapper {
				padding-top: 60px;
				display: grid;
				grid-row-gap: 20px;
				grid-auto-rows: 1fr;
				justify-content: space-between;
				grid-template-columns: calc(33.33% - 20px) calc(33.33% - 20px) calc(33.33% - 20px);
			}

			&__form {
				padding: 60px 0 0;
				gap: 15px;
				display: flex;
				flex-direction: column;

				@include max-width($scr-xs) {
					padding: 20px 0;
				}
			}

			&__form-container {
				border-radius: 15px;
				display: flex;
				flex-wrap: wrap;
				gap: 15px;

				.app-input,
				.app-select-input,
				.app-textarea,
				.app-file-upload-field {
					width: calc(50% - 15px);
				}

				@include max-width($scr-mm) {
					.app-input,
					.app-select-input,
					.app-textarea,
					.app-file-upload-field {
						width: 100%;
					}
				}

				@include max-width($scr-xs) {
					.textarea__footer {
						margin-right: 0;
						> p {
							margin-right: 0;
						}
					}
				}
			}
		}

		.submit-container {
			//
			.button-section {
				&__button {
					//
					@include max-width($scr-xc) {
						width: 100%;
					}
				}
			}
		}

		&__button-wrapper {
			margin-top: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
