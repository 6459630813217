@use "src/styles/vars.scss" as *;

.app-faq-card {
	.faq-card {
		padding: 24px;
		width: 100%;
		gap: 20px;
		border-image-slice: 1;
		border-bottom: 2px solid transparent;
		border-image-source: linear-gradient(90deg, rgba(12, 73, 146, 0) 0%, var(--theme-highlight) 16.67%, rgba(12, 73, 146, 0) 100%);
		display: flex;
		cursor: pointer;

		@include max-width($scr-xc) {
			padding: 13.24px 10px;
			gap: 10px;
		}

		&:hover {
			background-color: var(--c-dark-grey);
			transform: translateY(-5px);
			box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
		}

		&:hover::before {
			content: "";
			position: absolute;
			inset: -5px;
			border-radius: inherit;
			border: 2px solid rgba(255, 255, 255, 0.2);
			opacity: 0;
			animation: glow 0.5s ease forwards;
		}

		@keyframes glow {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}

		&__number-box {
			width: 51px;
			height: 51px;
			gap: 10px;
			border-radius: 8px;
			border: 1px solid var (--c-dark-grey);
			background-color: var(--c-border-grey);
			display: flex;
			align-items: center;
			justify-content: center;

			@include max-width($scr-xc) {
				min-width: 30px;
				width: 30px;
				height: 30px;
			}
		}

		&__number-text {
			margin: 0;
			color: var(--c-white);
			font-size: var(--text-size-m);
			font-weight: var(--text-weight-bold);

			@include max-width($scr-xc) {
				font-size: var(--text-size-xxs);
			}
		}

		&__question {
			margin: 0;
			color: var(--theme-text-color);
			overflow-wrap: break-word;
			word-break: break-word;
			font-weight: var(--text-weight-bold);
			font-size: calc(var(--text-size-l) - 2px);

			@include max-width($scr-xc) {
				font-size: var(--text-size-s);
			}
		}

		&__answer {
			padding-top: 10px;
			margin: 0;
			overflow-wrap: break-word;
			word-break: break-word;
			color: var(--theme-desc-color);
			font-size: var(--text-size-body);
			font-weight: var(--text-weight-regular);

			@include max-width($scr-xc) {
				font-size: var(--text-size-xs);
			}
		}

		&__icon {
			width: 24px;
			height: 24px;

            &.expanded {
                margin-top: 5px;
                align-self: baseline;
            }

			@include max-width($scr-xc) {
				width: 13.24px;
				height: 13.24px;
  			}
		}

		&__wrapper {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 2px;
		}
	}
}
