@use "src/styles/vars.scss" as *;

.app-footer {
	@include contentPadding;
	background-color: var(--theme-background-color);

	.footer {
		&__logo {
			padding: 30px 0;

			&--img {
				width: 150px;
				object-fit: contain;
			}
		}

		&__wrapper {
			gap: 20px;
			display: flex;
			margin-bottom: 30px;

			@include max-width($scr-mm) {
				gap: 30px;
				flex-direction: column;
			}
		}

		&__cta-wrapper {
			display: flex;
			gap: 10px;
			flex-direction: column;

			&--text {
				margin: 0;
				color: var(--theme-text-color);
				font-size: var(--text-size-xs);
				font-weight: var(--text-weight-regular);
			}
		}

		&__img {
			width: 160px;
			height: 30px;
		}

		&__media {
			display: flex;
			gap: 10px;
		}

		&__divider {
			border: 1px solid var(--c-dark-grey);
		}

		&__section {
			padding: 20px 0 30px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include max-width($scr-mm) {
				flex-direction: column-reverse;
				gap: 30px;
				align-items: center;
			}

			@include max-width($scr-xc) {
				font-size: var(--text-size-xs);
				flex-direction: row;
			}
		}

		&__text {
			margin: 0;
			color: var(--theme-text-color-2nd);
			font-size: var(--text-size-s);
			font-weight: var(--text-weight-regular);

			@include max-width($scr-xc) {
				font-size: var(--text-size-xs);
			}
		}

		&__navigation {
			display: flex;
		}
	}

	.footer-address {
		display: flex;
		flex-direction: column;
		flex: 0 1 20%;
		gap: 20px;

		@include max-width($scr-xc) {
			gap: 10px;
		}

		&__title {
			margin: 0;
			color: var(--theme-text-color);
			font-size: var(--text-size-m);
			font-weight: var(--text-weight-semibold);

			@include max-width($scr-xc) {
				font-size: var(--text-size-body);
			}
		}

		&__description {
			margin: 0;
			color: var(--theme-text-color-2nd);
			overflow-wrap: break-word;
			font-size: var(--text-size-s);
			font-weight: var(--text-weight-normal);

			@include max-width($scr-xc) {
				font-size: var(--text-size-xs);
			}
		}

		&__address {
			margin: 0;
		}
	}

	.footer-link {
		&__container {
			margin: 0;
			padding: 0;
			width: 44px;
			height: 44px;
			border-radius: 6px;
			background-color: var(--c-box-grey);
			border: 1px solid var(--c-dark-grey);
		}

		&__icon {
			width: 20px;
			height: 20px;
		}

		&__transparent-container {
			position: relative;
			border: 0;
			padding: 0 20px;
			background-color: transparent;

			&:not(:last-child)::after {
				content: "";
				height: 100%;
				width: 1px;
				position: absolute;
				right: 0;
				top: 0;
				background-color: var(--c-dark-grey);
			}

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		&__text {
			margin: 0;
			color: var(--theme-text-color-2nd);
			font-size: var(--text-size-s);
			font-weight: var(--text-weight-regular);
		}
	}
}
