@use "src/styles/vars.scss" as *;

.app-service-solution-card {
	border-radius: 10px;
	border: 1px solid var(--c-dark-grey);
	background: linear-gradient(0deg, var(--c-dark-black), var(--c-dark-black));
	display: flex;
	overflow: hidden;
	flex: 0 1 calc(33.33% - 20px);
	justify-content: space-between;

	@include max-width($scr-mm) {
		flex: 0 1 calc(50% - 20px);
	}

	@include max-width($scr-xc) {
		flex: 0 1 100%;
	}

	.service-card {
		width: 100%;
		&__image {
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}

		&__wrapper {
			padding: 40px;
			@include max-width($scr-xc) {
				padding: 30px;
			}
		}

		&__title {
			margin: 0;
			color: var(--c-white);
			overflow-wrap: break-word;
			font-weight: var(--text-weight-bold);
			font-size: calc(var(--text-size-m) + 2px);
			text-transform: uppercase;
			border-bottom: 2px solid var(--c-white);

			@include max-width($scr-xc) {
				font-size: var(--text-size-body);
				border: 0;
			}
		}

		&__description {
			margin: 0;
			padding-top: 20px;
			color: var(--c-soft-White);
			overflow-wrap: break-word;
			font-size: calc(var(--text-size-body));
			font-weight: var(--text-weight-normal);

			@include max-width($scr-xc) {
				font-size: var(--text-size-s);
			}
		}
	}
}
