@use "src/styles/vars.scss" as *;

.page-home {
	scroll-behavior: smooth;
	background-color: var(--theme-background-color);

	.nav-menu {
		gap: 30px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.nav-item {
			margin: 0;
			font-weight: 300;
			text-decoration: none;
			color: var(--theme-text-color);
			font-size: var(--text-size-body);
		}
	}

	.home {
		height: 100%;

		.home-container {
			position: relative;
		}

		&__height {
			height: 800px;
			opacity: 0.6;
			overflow: hidden;
		}

		&__bg-video {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		@include max-width($scr-sm) {
			.home {
				&__height {
					height: 800px;
				}
			}
		}

		.home-wrapper {
			display: flex;
			justify-content: flex-start;
		}

		.context {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;

			&__top-nav {
				padding: 24px;
				height: 96px;

				&--menu {
					float: right;
					cursor: pointer;
				}
			}

			&__cover-text {
				display: block;
				max-width: 80%;
				text-align: center;

				@include max-width($scr-xs) {
					max-width: 100%;
				}
			}

			&__text {
				color: var(--c-white);
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-items: center;
				text-align: center;
				font-size: var(--text-size-xxxl);
				font-weight: 300;

				@include max-width($scr-lg) {
					font-size: 35px;
				}

				@include max-width($scr-xs) {
					font-size: 28px;
				}

				@include max-width($scr-xx) {
					font-size: calc(var(--text-size-m) + 2px);
				}

				&--black {
					color: var(--c-pure-black);
				}

				&__pre {
					&__wrapper {
						display: flex;
						translate: 200px;
						animation: move-left-200 0.5s forwards;
						animation-delay: 0.5s;

						@include max-width($scr-xs) {
							translate: 150px;
							animation: move-left-150 0.5s forwards;
							animation-delay: 0.5s;
						}

						@include max-width($scr-xx) {
							translate: 100px;
							animation: move-left-100 0.5s forwards;
							animation-delay: 0.5s;
						}
					}

					opacity: 0;

					@include max-width($scr-xs) {
						margin-right: 8px !important;
					}

					@include max-width($scr-xx) {
						margin-right: 5px !important;

						&:nth-child(5) {
							margin-right: 0px !important;
						}

						font-size: 20px;
					}

					&:nth-child(1) {
						animation: first-word-entrance 0.5s forwards;
						animation-delay: 0s;
						margin-right: 15px;
					}

					&:nth-child(2) {
						animation: fade-in forwards;
						animation-delay: 0.55s;
						margin-right: 15px;
					}

					&:nth-child(3) {
						animation: fade-in forwards;
						animation-delay: 0.6s;
						margin-right: 15px;
					}

					&:nth-child(4) {
						animation: fade-in forwards;
						animation-delay: 0.7s;
						margin-right: 15px;
					}

					&:nth-child(5) {
						animation: fade-in forwards;
						animation-delay: 0.85s;
					}

					@keyframes first-word-entrance {
						0% {
							opacity: 0;
							transform: scale(10);
						}

						50% {
							opacity: 1;
							transform: scale(1);
						}

						100% {
							opacity: 1;
							transform: scale(1);
						}
					}

					@keyframes move-left-100 {
						0% {
							transform: translateX(0);
						}

						100% {
							transform: translateX(-100px);
						}
					}

					@keyframes move-left-150 {
						0% {
							transform: translateX(0);
						}

						100% {
							transform: translateX(-150px);
						}
					}

					@keyframes move-left-200 {
						0% {
							transform: translateX(0);
						}

						100% {
							transform: translateX(-200px);
						}
					}

					@keyframes fade-in {
						0% {
							opacity: 0;
						}

						100% {
							opacity: 1;
						}
					}
				}
			}

			.content {
				&__container {
					display: flex;
					justify-content: center;
					align-items: center;

					.text {
						display: inline;
						float: left;
						margin: 0;
						font-weight: var(--text-weight-bold);
					}

					.list {
						padding: 0;
						margin: 0;
						list-style: none;
						//overflow: hidden;
						height: 100px;

						@include max-width($scr-md) {
							display: block;
						}

						@include max-width($scr-lg) {
							padding: 0;
							margin: 0;
							height: 200px;
							width: 700px;
						}

						@include max-width($scr-xs) {
							height: 120px;
							width: 340px;
						}

						&__item {
							margin: 0;
							color: var(--c-white);
							font-size: var(--text-size-xxxxl);
							font-weight: var(--text-weight-bold);
							display: inline-block;

							@include max-width($scr-xs) {
								font-size: 28px;
								padding: 8px 16px;
							}
						}
					}
				}
			}

			.slide-up-wording {
				animation: scrollUp 3s ease forwards;
			}

			.typewriter {
				overflow: hidden;
				margin: 0 auto;
				white-space: nowrap;
				animation: typeWriter 0.5s ease forwards;
			}

			.pulse {
				animation: pulse 0.5s ease forwards;
			}
		}

		.partners-section {
			margin-bottom: 50px;
			background-color: var(--theme-background-color);

			&__title {
				margin: 0;
				padding: 60px 0 40px;
				text-align: center;
				color: var(--theme-text-color);
				text-transform: uppercase;
				font-size: calc(var(--text-size-l) + 2px);
				font-weight: var(--text-weight-normal);

				@include max-width($scr-xc) {
					padding: 30px 0;
				}
			}
		}

		.services-section {
			@include sectionMarginAndPadding;
			width: 100%;
			background-color: var(--theme-background-color);

			@include max-width($scr-xc) {
				margin-bottom: 40px;
			}

			&__title {
				@include titleFont;
				margin: 0;
				text-align: left;
				color: var(--theme-text-color);
				overflow-wrap: break-word;
				font-weight: var(--text-weight-bold);
			}

			&__description {
				margin: 0;
				padding-top: 10px;
				overflow-wrap: break-word;
				color: var(--theme-text-color-3rd);
				font-size: var(--text-size-m);
				font-weight: var(--text-weight-regular);

				@include max-width($scr-xc) {
					font-size: var(--text-size-s);
				}

				p {
					margin: 0;
				}

				p:not(:last-child) {
					padding-bottom: 30px;

					@include max-width($scr-xc) {
						padding-bottom: 20px;
					}
				}
			}

			&__subtitle {
				margin: 0;
				padding: 50px 0 40px 0;
				text-align: left;
				color: var(--theme-text-color);
				overflow-wrap: break-word;
				text-transform: uppercase;
				font-weight: var(--text-weight-bold);
				font-size: 24px;
				display: flex;
				align-items: center;

				&::after {
					content: "";
					flex-grow: 1;
					margin-left: 20px;
					border-top: 2px solid var(--c-divider);

					@include max-width($scr-xc) {
						border: none;
					}
				}

				@include max-width($scr-xc) {
					padding: 40px 0 20px 0;
					font-size: 20px;
				}
			}

			&__wrapper {
				padding: 0;
				gap: 20px;
				display: flex;
				flex-wrap: wrap;
				align-items: stretch;
				justify-content: flex-start;

				@include max-width($scr-xs) {
					flex-direction: column;
				}
			}

			&__button-container {
				margin-top: 40px;
				display: flex;
				align-items: center;
				justify-content: center;

				@include max-width($scr-xc) {
					margin-top: 30px;
					font-size: var(--text-size-s);

					> button {
						width: 123px;
						min-width: 123px;
						height: 44px;
						padding: 10px 20px;
					}
				}
			}
		}

		.company-intro {
			@include sectionMarginAndPadding;
			width: 100%;
			background-color: var(--theme-background-color);

			@include max-width($scr-xc) {
				margin-top: 40px;
			}

			&__title {
				@include titleFont;
				margin: 0 0 20px 0;
				text-align: left;
				color: var(--theme-text-color);
				overflow-wrap: break-word;
				font-weight: var(--text-weight-bold);
			}

			&__wrapper {
				display: grid;
				grid-gap: 20px;
				grid-template-columns: repeat(3, 1fr);

				@include max-width($scr-md) {
					grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
				}

				@include max-width($scr-xx) {
					display: flex;
					flex-direction: column;
					gap: 20px;
				}
			}
		}

		.articles-section {
			@include sectionMarginAndPadding;

			background-color: var(--theme-background-color);

			&__title {
				@include titleFont;
				margin: 0;
				padding: 0;
				text-align: left;
				color: var(--theme-text-color);
				text-transform: uppercase;
				font-weight: var(--text-weight-bold);
			}

			&__wrapper {
				display: grid;
				grid-gap: 40px;
				grid-template-columns: repeat(3, 1fr);

				@include max-width($scr-md) {
					grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
				}

				@include max-width($scr-xc) {
					grid-gap: 30px;
				}
			}

			&__header {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-between;
				//padding-top: 50px;
				//border-top: 1px solid var(--c-divider);
				margin-bottom: 40px;

				@include max-width($scr-xc) {
					margin-bottom: 20px;
				}
			}

			&__btn-top {
				display: block;

				@include max-width($scr-xc) {
					display: none;
				}
			}

			&__btn-bottom {
				display: none;

				@include max-width($scr-xc) {
					display: block;
					margin: auto;
					height: 41px;
					width: 147px;
					padding: 10px 20px;
				}
			}
		}
	}

	.faq-section {
		@include sectionMarginAndPadding;
		width: 100%;
		background-color: var(--theme-background-color);

		&__title {
			@include titleFont;
			margin: 0;
			text-align: left;
			color: var(--theme-text-color);
			overflow-wrap: break-word;
			text-transform: uppercase;
			font-weight: var(--text-weight-bold);
		}

		&__description {
			margin: 0;
			padding: 10px 0 50px;
			overflow-wrap: break-word;
			color: var(--theme-text-color-3rd);
			font-size: var(--text-size-m);
			font-weight: var(--text-weight-regular);

			@include max-width($scr-xc) {
				font-size: var(--text-size-s);
				padding: 6px 0 20px;
			}
		}

		&__container {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-auto-rows: auto;

			@include max-width($scr-md) {
				grid-template-columns: 1fr;
				display: flex;
				flex-direction: column;
			}
		}
	}

	@include max-width($scr-xs) {
		.home {
			overflow-x: hidden;
		}
	}

	.fadeInAndUp {
		animation: fadeInAndUp 1s ease forwards;
	}

	.fadeInAndDown {
		animation: fadeInAndDown 1s ease forwards;
	}

	.fadeInAndLeft {
		animation: fadeInAndLeft 1s ease forwards;
	}

	.fadeInAndRight {
		animation: fadeInAndRight 1s ease forwards;
	}
}

@keyframes zoom-out {
	from {
		transform: scale(2);
	}

	to {
		transform: scale(1);
	}
}

@keyframes fadeInAndUp {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes fadeInAndDown {
	0% {
		opacity: 0;
		transform: translateY(-50px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes fadeInAndLeft {
	0% {
		opacity: 0;
		transform: translateX(100px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes fadeInAndRight {
	0% {
		opacity: 0;
		transform: translateX(-100px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes scrollUp {
	0% {
		transform: translateY(100%);
	}

	25%,
	50%,
	75% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-100%);
	}
}

@keyframes typeWriter {
	0% {
		width: 0;
		transform: scale(1);
	}

	75% {
		width: 100%;
		transform: scale(1);
	}

	100% {
		width: 100%;
		transform: scale(0.9);
	}
}

@keyframes pulse {
	0% {
		opacity: 0;
		transform: scale(0.9);
	}

	15% {
		opacity: 1;
		transform: scale(1.05);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.dud {
	color: #757575;
}
