@use "src/styles/vars.scss" as *;

.page-project {
	scroll-behavior: smooth;
	background-color: var(--theme-background-color);

	.project {
		height: 100%;

		.clients-section {
			height: 600px;
			padding: 20px 8% 20px;
			background-color: var(--theme-background-color);
			display: flex;
			justify-content: flex-end;

			&__youtube {
				width: 100%;
				overflow: hidden;
				aspect-ratio: 16/9;
				pointer-events: none;
			}

			.clients-section__youtube iframe {
				width: 300%;
				height: 100%;
				margin-left: -100%;
			}

			&__body {
				position: relative;
				height: 100%;
				width: 100%;
				border-radius: 10px;
				overflow: hidden;
			}

			&__wrapper {
				z-index: 99;
				position: absolute;
				padding: 60px 40px 0;
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
			}

			&__title {
				margin: 0;
				padding: 0 0 10px;
				color: var(--c-white);
				text-transform: uppercase;
				font-weight: var(--text-weight-bold);
				font-size: var(--title-font-size);
				text-shadow: 4px 4px 6px rgba(66, 68, 90, 1);
				filter: drop-shadow(2px 4px 6px black);
			}

			&__sub-title {
				margin: 0;
				padding: 0 0 10px;
				color: var(--c-white);
				font-size: var(--text-size-xl);
				font-weight: var(--text-weight-normal);
				text-shadow: 4px 4px 6px rgba(66, 68, 90, 1);
				filter: drop-shadow(2px 4px 6px black);
			}

			&__description {
				margin: 0;
				color: var(--c-white);
				overflow-wrap: break-word;
				font-size: var(--text-size-m);
				font-weight: var(--text-weight-thin);
				text-shadow: 4px 4px 6px rgba(66, 68, 90, 1);
				filter: drop-shadow(2px 4px 6px black);
			}

			.app-pagination {
				position: absolute;
				bottom: 40px;
				left: 40px;
			}

			.video {
				height: 100%;
				width: 100%;
				object-fit: cover;
				pointer-events: none;
				opacity: 0.5;
				&::-webkit-media-controls {
					display: none;
				}
			}

			.image {
				height: 100%;
				width: 100%;
				object-fit: cover;
				opacity: 0.5;
			}
		}
	}
}
