@use "src/styles/vars.scss" as *;

.content-tabs {
	margin: 0 auto;
	width: fit-content;
	background-color: var(--theme-background-color);
	display: flex;
	overflow-x: auto;

	@include max-width($scr-xx) {
		width: fit-content;
	}

	@include max-width($scr-xc) {
		width: fit-content;
	}

	.tab {
		min-width: 245px;
		height: 52px;
		background-color: var(--theme-background-color);
		font-size: var(--text-size-body);
		font-weight: var(--text-weight-normal);
		flex: 1;
		text-align: center;
		border-bottom: none;
		white-space: normal;
		box-sizing: border-box;

		@media (max-width: 601px) {
			margin: 0;
			width: auto;
			font-size: var(--text-size-xs);
		}

		@media (max-width: $scr-xc) {
			margin: 0;
			width: auto;
			font-size: var(--text-size-xxs);
			word-wrap: break-word;
		}
	}
}
