@use "src/styles/vars.scss" as *;

.page-404 {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	gap: 40px;

	&__animate {
		width: 80px;
		aspect-ratio: 1;
		border: 10px solid var(--c-pure-black);
		box-sizing: border-box;
		background: radial-gradient(farthest-side, var(--c-white) 98%, var(--c-pure-black)) 0 0/20px 20px, radial-gradient(farthest-side, var(--c-white) 98%, var(--c-pure-black)) 100% 0/20px 20px,
			radial-gradient(farthest-side, var(--c-white) 98%, var(--c-pure-black)) 100% 100%/20px 20px, radial-gradient(farthest-side, var(--c-white) 98%, var(--c-pure-black)) 0 100%/20px 20px,
			linear-gradient(var(--c-white) 0 0) 50%/40px 40px, var(--c-pure-black);
		background-repeat: no-repeat;
		filter: blur(4px) contrast(10);
		animation: animate 0.8s infinite;
	}

	&__title {
		width: 80%;
		margin: 0;
		color: var(--c-white);
		font-size: var(--text-size-xxxl);
		font-weight: var(--text-weight-bold);
		animation: fade 1s infinite;
	}

	&__cta {
		padding: 10px 30px;
		border-radius: 50px;
		font-size: var(--text-size-m);
		color: var(--c-white);
		background-color: transparent;
		border: 1px solid var(--c-white);
		text-transform: uppercase;
		cursor: pointer;
		transition: all 0.2s;

		&:hover {
			transform: scaleX(1.1) scaleY((1.2));
		}

		&:active,
		&:focus {
			outline: none;
		}
	}

	@include max-width($scr-xs) {
		&__title {
			font-size: var(--text-size-xl);
		}

		&__cta {
			font-size: var(--text-size-s);
		}
	}
}

@keyframes animate {
	100% {
		background-position: 100% 0, 100% 100%, 0 100%, 0 0, center;
	}
}
