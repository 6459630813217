:root {
	/* -- Font size -- */
	--text-size-xxxxl: 64px;
	--text-size-xxxl: 48px;
	--text-size-xxl: 32px;
	--text-size-xl: 28px;
	--text-size-l: 22px;
	--text-size-m: 18px;
	--text-size-body: 16px;
	--text-size-s: 14px;
	--text-size-xs: 12px;
	--text-size-xxs: 10px;
	--text-size-xxxs: 9px;

	/* -- Font weight -- */
	--text-weight-strong: 900;
	--text-weight-exbold: 800;
	--text-weight-bold: 700;
	--text-weight-semibold: 600;
	--text-weight-normal: 500;
	--text-weight-regular: 400;
	--text-weight-thin: 300;
	--text-weight-light: 200;
	--text-weight-exlight: 100;

    /* -- Font Family -- */
}
