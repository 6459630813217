@use "src/styles/vars.scss" as *;

.page-connect-with-us {
	scroll-behavior: smooth;
	background-color: var(--theme-background-color);
	width: 100%;

	.connect-with-us {
		&__description {
			max-width: calc(100% - 200px);
			font-size: var(--text-size-m);
			font-weight: var(--text-weight-normal);
			text-align: left;
			color: var(--theme-text-color);

			@include max-width($scr-sm) {
				max-width: 100%;
			}

			@include max-width($scr-xc) {
				font-size: var(--text-size-s);
				margin-bottom: 0;
			}
		}

		&__form {
			padding: 30px 8% 0px;
			gap: 15px;
			background-color: var(--theme-background-color);
			display: flex;
			flex-direction: column;

			@include max-width($scr-xs) {
				padding: 20px 8%;
			}

			@include max-width($scr-xc) {
				gap: 10px;
				padding: 20px 5%;
			}
		}

		&__form-container {
			border-radius: 15px;
			display: flex;
			flex-wrap: wrap;
			gap: 20px;

			.app-input,
			.app-select-input {
				width: calc(50% - 10px);
			}

			.app-textarea {
				width: 100%;

				.textarea__error {
					margin: 0px;
				}
			}

			@include max-width($scr-xs) {
				.app-input,
				.app-select-input {
					width: 100%;
				}
			}
		}

		.submit-container {
			//
			.button-section {
				&__button {
					//
					@include max-width($scr-xc) {
						width: 100%;
					}
				}
			}
		}

		.content {
			display: flex;
			position: relative;
			padding: 20px 8% 60px;
			background-color: var(--theme-background-color);
			gap: 20px;

			@include max-width($scr-xc) {
				padding: 20px 5% 60px;
			}

			&__left {
				width: 37%;
			}

			&__right {
				width: 63%;
			}
		}

		.content::after {
			content: "";
			position: absolute;
			width: 84%;
			bottom: 0;
			border-bottom: 1px solid var(--c-divider);
		}
	}
}

.services-section {
	padding: 50px 8% 0px;
	background-color: var(--theme-background-color);

	@include max-width($scr-xc) {
		padding: 40px 5% 0px;
	}

	&__title {
		@include titleFont;
		margin: 0;
		padding-bottom: 20px;
		text-align: left;
		color: var(--theme-text-color);
		text-transform: none;
		font-weight: var(--text-weight-bold);
	}

	&__description {
		margin: 0;
		color: var(--theme-text-color);
		font-size: var(--text-size-m);
		font-weight: var(--text-weight-regular);
	}

	&__header {
		gap: 40px;
		display: flex;
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		padding-bottom: 50px;
	}
}
