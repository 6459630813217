@use "src/styles/vars.scss" as *;

.page-our-story {
	position: relative;
	scroll-behavior: smooth;

	.static-background {
		position: fixed;
		z-index: 1;
		width: 100vw;
		height: 100vh;
		background-color: rgba(15, 15, 15, 0.70);
		background-blend-mode: overlay;
        background-position: top;
	}

	.story {
		position: relative;
		z-index: 2;
		height: 100%;

		.timeline {
			display: flex;
			flex-direction: column;
			padding: 60px 8%;
			color: var(--c-white);
			position: relative;
		}
	}

	.app-footer {
		position: relative;
		z-index: 2;
	}
}
