@use "src/styles/vars.scss" as *;

.page-article {
	scroll-behavior: smooth;
	background-color: var(--theme-background-color);

	.article {
		height: 100%;

		&__header {
			position: relative;
			height: 500px;
			width: 100%;

			@include max-width($scr-sm) {
				height: 350px;
			}
		}

		&__header-img {
			height: 100%;
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: 0% 35%;

			@include max-width($scr-sm) {
				background-position: center;
			}
		}

		&__header-text {
			width: 100%;
			display: flex;
			justify-content: center;
			position: absolute;
			bottom: 20px;
			text-align: center;
			color: var(--c-white);
			font-size: var(--title-font-size);
			font-weight: var(--text-weight-semibold);
			text-shadow: 4px 4px 15px rgba(0, 0, 0, 1);

			p {
				width: 96%;
			}

			@include max-width($scr-sm) {
				font-size: var(--article-title-font-size);
			}

			@include max-width($scr-xc) {
				font-size: var(--text-size-xl);
			}
		}

		.content {
			display: flex;
			position: relative;
			padding: 20px 8% 60px;
			background-color: var(--theme-background-color);
			gap: 20px;

			&__left {
				width: 37%;
				@include max-width($scr-mm) {
					width: 100%;
					margin-bottom: 30px;
				}
			}

			&__right {
				width: 63%;
				@include max-width($scr-mm) {
					width: 100%;
				}
			}

			@include max-width($scr-mm) {
				display: block;
			}

			@include max-width($scr-xc) {
				padding: 20px 5% 50px;
			}
		}

		.content::after {
			content: "";
			position: absolute;
			width: 84%;
			bottom: 0;
			border-bottom: 1px solid var(--c-divider);
		}

		.articles-section {
			padding: 60px 8% 20px 8%;
			background-color: var(--theme-background-color);

			@include max-width($scr-md) {
				padding: 40px 8%;
			}

			&__title {
				@include titleFont;
				margin: 0;
				text-align: left;
				color: var(--theme-text-color);
				font-weight: var(--text-weight-bold);
			}

			&__header {
				padding-bottom: 40px;
				gap: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
			}

			&__wrapper {
				display: grid;
				grid-row-gap: 60px;
				grid-auto-rows: 1fr;
				justify-content: space-between;
				grid-gap: 40px;
				grid-template-columns: repeat(3, 1fr);

				@include max-width($scr-md) {
					grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
				}

				@include max-width($scr-xc) {
					grid-gap: 20px;
				}
			}

			@include max-width($scr-xc) {
				padding: 40px 5%;

				&__header {
					padding-bottom: 20px;
				}
			}

			.view-button {
				padding: 0;
				height: 52px;
				width: 130px;
				gap: 3px;
				border-radius: 8px;
				border: 1px solid var(--c-dark-grey);
				background-color: var(--c-button-black);
				display: flex;
				align-items: center;
				justify-content: center;

				&__text {
					margin: 0;
					color: var(--theme-text-color-2nd);
					font-size: var(--text-size-body);
					font-weight: var(--text-weight-regular);
				}
			}

			&__button-wrapper {
				margin-top: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}
