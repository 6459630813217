@use "src/styles/vars.scss" as *;

.app-news-card {
	.news-card {
		margin: 40px 8% 50px;

		@include max-width($scr-lg) {
			margin: 20px 8% 50px;
		}

		@include max-width($scr-xc) {
			margin: 20px 5% 50px;
		}

		&__image {
			height: 250px;
			border-radius: 8px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;

			@include max-width($scr-xc) {
				height: auto;
			}
		}

		&__title {
			margin: 0;
			color: var(--c-white);
			font-size: var(--text-size-xxl);
			font-weight: var(--text-weight-bold);
			@include max-width($scr-xm) {
				font-size: var(--text-size-l);
			}

			@include max-width($scr-xc) {
				font-size: var(--text-size-s);
				margin-bottom: 6px;
			}
		}

		&__description {
			margin: 0;
			color: var(--c-white);
			font-size: var(--text-size-m);
			font-weight: var(--text-weight-thin);

			@include max-width($scr-xm) {
				font-size: var(--text-size-s);
			}

			@include max-width($scr-xc) {
				font-size: var(--text-size-xxs);
			}
		}

		&__wrapper {
			display: flex;
			flex-direction: column;
			padding-right: 10px;
		}

		&__container {
			padding: 0 40px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include max-width($scr-xm) {
				padding: 30px;
				gap: 10px;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
			}

			@include max-width($scr-xc) {
				padding: 20px;
			}
		}

		&__btn {
			//
			@include max-width($scr-xc) {
				min-width: 88px;
				height: 28px;
				padding: 0 10px;

				> div {
					font-size: var(--text-size-xxs);
				}
			}
		}
	}
}
