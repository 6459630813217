@use "src/styles/vars.scss" as *;

.app-article-resource-player {
	margin-top: 40px;
	display: flex;
	justify-content: center;

	.article-resource-player {
		width: fit-content;
		min-width: 200px;
		position: relative;
		background-color: var(--c-dark-grey);

		@include max-width($scr-sm) {
			width: 100%;
		}

		&__item {
			height: 300px;
			display: flex;
			justify-content: center;

			.image {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			@include max-width($scr-sm) {
				max-height: 160px;
			}
		}

		.app-pagination {
			width: 100%;
			position: absolute;
			bottom: 0;
			background-color: transparent;
			border: unset;

			.pagination {
				justify-content: space-between;

				&__container {
					background-color: var(--c-dark-black);
					border-color: var(--c-border-grey);
				}
			}
		}
	}
}
