.category-tabs {
	background-color: var(--theme-background-color);
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 0;

	.MuiTabs-scrollButtons {
		background-color: var(--theme-background-color);
		color: var(--theme-text-color);
	}

	.tab {
		background-color: var(--theme-background-color);
		color: var(--theme-desc-color);
		font-size: var(--text-size-body);
		font-weight: var(--text-weight-normal);
		flex: 1;
		text-align: center;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			width: 2px;
			height: 40px;
			background-color: var(--c-white);
			opacity: 0.1;
		}

		&:last-child::after {
			display: none;
		}
	}
}
