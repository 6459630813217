.app-popup-card {
	position: relative;
	margin: 20px;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;

	.popup-card {
		width: 100%;
		max-width: 1000px;
		padding: 100px 35px;
		margin: 0 auto;
		border-radius: 10px;
		background-color: var(--c-dark-grey);
		outline: none !important;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		&__icon {
			width: 120px;
			padding-bottom: 50px;
		}

		&__title {
			margin: 0;
			text-align: center;
			color: var(--c-white);
			font-size: var(--text-size-xxl);
			font-weight: var(--text-weight-bold);
		}
	}
}
