@use "src/styles/vars.scss" as *;

.app-textarea {
	min-width: 250px;

	.textarea {
		&__required {
			color: var(--theme-highlight);
			font-size: var(--text-size-xs);
			font-weight: var(--text-weight-semibold);
			vertical-align: text-top;
		}

		&__label {
			margin-bottom: 10px;
			width: 100%;
			color: var(--theme-text-color);
			font-size: var(--text-size-body);
			font-weight: var(--text-weight-semibold);

			@include max-width($scr-xc) {
				margin-bottom: 5.42px;
				font-size: var(--text-size-xs);
			}
		}

		&__input {
			width: 100%;
			height: 150px;
			background-color: transparent;
			border-radius: 6px;
			color: var(--c-white);
			border: 0;
			resize: none;
			outline: none;
			box-sizing: border-box;

			&::placeholder {
				color: var(--theme-placeholder);
			}

			&:disabled {
				background-color: transparent;
			}

			@include max-width($scr-xc) {
				font-size: var(--text-size-xxs);
				height: 80px;
			}
		}

		&__icon {
			width: 25px;
			height: 25px;
			margin: 0 0 0 10px;

			img {
				width: 100%;
			}
		}

		&__body {
			padding: 12px;
			border-radius: 6px;
			border: 1px solid var(--c-border-grey);
			background-color: var(--c-box-grey);
			transition: var(--transition);
			display: flex;
			align-items: center;

			&:focus-within {
				border: 1px solid var(--c-black);
			}

			@include max-width($scr-xc) {
				padding: 8.68px 10.85px;
			}
		}

		&__footer {
			margin: 10px 10px 0px 0px;
		}

		&__error {
			margin: 10px 10px 0 0;
			color: var(--c-error-message);
			word-break: break-word;
			font-size: var(--text-size-xs);
			display: flex;
		}

		&__count {
			margin-left: auto;
			color: var(--theme-text-color-3rd);
			font-size: var(--text-size-xs);

			@include max-width($scr-xc) {
				font-size: var(--text-size-xxs);
			}
		}

		&--error {
			.textarea {
				&__body {
					border: 1px solid var(--c-error-message);
				}
			}
		}

		&--disabled {
			.textarea {
				&__label {
					background-color: var(--c-disabled);
				}

				&__body {
					background-color: var(--c-disabled);
				}
			}
		}

		&--custom-height {
			.textarea__body {
				height: 96px;
				box-sizing: border-box;
			}

			.textarea__input {
				height: 100%;
				box-sizing: border-box;
			}
		}
	}
}
